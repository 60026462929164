// Custom.scss
// Option B: Include parts of Bootstrap

// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import "../node_modules/bootstrap/scss/functions";

// 2. Include any default variable overrides here
$logo-color: #ff5400;

$domaine-disciplinaire-langues-color : rgb(230, 204, 0) ;
$domaine-disciplinaire-mathematiques-et-sciences-de-la-nature-color : rgb(204, 0, 1) ;
$domaine-disciplinaire-sciences-humaines-et-sociales-color : rgb(0, 143, 20) ;
$domaine-disciplinaire-arts-color : rgb(255, 89, 51) ;
$domaine-disciplinaire-corps-et-mouvement-color : rgb(0, 166, 255) ;
$domaine-disciplinaire-formation-generale-color : rgb(76, 38, 110) ;
$domaine-disciplinaire-capacites-transversales-color : rgb(159, 109, 62) ;
$domaine-disciplinaire-education-numerique-color : rgb(189, 78, 161) ;

//$domaine-disciplinaire-langues: ;
$custom-colors: (
  "logo-color" : $logo-color,
  "domaine-disciplinaire-langues-color" : $domaine-disciplinaire-langues-color,
  "domaine-disciplinaire-mathematiques-et-sciences-de-la-nature-color" : $domaine-disciplinaire-mathematiques-et-sciences-de-la-nature-color,
  "domaine-disciplinaire-sciences-humaines-et-sociales-color" : $domaine-disciplinaire-sciences-humaines-et-sociales-color,
  "domaine-disciplinaire-arts-color" : $domaine-disciplinaire-arts-color,
  "domaine-disciplinaire-corps-et-mouvement-color" : $domaine-disciplinaire-corps-et-mouvement-color,
  "domaine-disciplinaire-formation-generale-color" : $domaine-disciplinaire-formation-generale-color,
  "domaine-disciplinaire-capacites-transversales-color" : $domaine-disciplinaire-capacites-transversales-color,
  "domaine-disciplinaire-education-numerique-color" : $domaine-disciplinaire-education-numerique-color,

);
// 3. Include remainder of required Bootstrap stylesheets (including any separate color mode stylesheets)
@import "../node_modules/bootstrap/scss/variables";
@import "../node_modules/bootstrap/scss/variables-dark";

// 4. Include any default map overrides here
$theme-colors: map-merge($theme-colors, $custom-colors);

// 5. Include remainder of required parts
@import "../node_modules/bootstrap/scss/maps";
@import "../node_modules/bootstrap/scss/mixins";
@import "../node_modules/bootstrap/scss/root";

// 6. Optionally include any other parts as needed
@import "../node_modules/bootstrap/scss/utilities";
@import "../node_modules/bootstrap/scss/accordion";
@import "../node_modules/bootstrap/scss/alert";
@import "../node_modules/bootstrap/scss/badge";
@import "../node_modules/bootstrap/scss/breadcrumb";
@import "../node_modules/bootstrap/scss/button-group";
@import "../node_modules/bootstrap/scss/buttons";
@import "../node_modules/bootstrap/scss/card";
@import "../node_modules/bootstrap/scss/carousel";
@import "../node_modules/bootstrap/scss/close";
@import "../node_modules/bootstrap/scss/containers";
@import "../node_modules/bootstrap/scss/dropdown";
@import "../node_modules/bootstrap/scss/forms";
@import "../node_modules/bootstrap/scss/grid";
@import "../node_modules/bootstrap/scss/helpers";
@import "../node_modules/bootstrap/scss/images";
@import "../node_modules/bootstrap/scss/list-group";
@import "../node_modules/bootstrap/scss/modal";
@import "../node_modules/bootstrap/scss/nav";
@import "../node_modules/bootstrap/scss/navbar"; // Requires nav
@import "../node_modules/bootstrap/scss/offcanvas"; // Requires transitions
@import "../node_modules/bootstrap/scss/pagination";
@import "../node_modules/bootstrap/scss/placeholders";
@import "../node_modules/bootstrap/scss/popover";
@import "../node_modules/bootstrap/scss/progress";
@import "../node_modules/bootstrap/scss/reboot";
@import "../node_modules/bootstrap/scss/spinners";
@import "../node_modules/bootstrap/scss/tables";
@import "../node_modules/bootstrap/scss/toasts";
@import "../node_modules/bootstrap/scss/tooltip";
@import "../node_modules/bootstrap/scss/transitions"; // Requires transitions
@import "../node_modules/bootstrap/scss/type";

// 7. Optionally include utilities API last to generate classes based on the Sass map in `_utilities.scss`
@import "../node_modules/bootstrap/scss/utilities/api";
@import "../node_modules/bootstrap-icons/font/bootstrap-icons";

// 8. Add additional custom code here
@import '../node_modules/ngx-toastr/toastr-bs5-alert.scss';
@import '../node_modules/quill/dist/quill.core.css';
@import '../node_modules/quill/dist/quill.snow.css';
@import 'print.scss';


body {
  @include media-breakpoint-up(sm) {
    overflow-y: scroll;
  }
  section {
    padding: 10px 0;
    min-height: 350px;
  }
}

.clickable:hover, .clickable:focus-within{
  cursor: pointer;
  transition: all 100ms ease-in-out;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

a:link { text-decoration: none; }
a:visited { text-decoration: none; }
a:hover { text-decoration: none; }
a:active { text-decoration: none; }

.nav .edicons-visualidentity-iclasse,
.jumbotron .edicons-visualidentity-iclasse {
  color: $logo-color;
}
.btn-toolbar {
  margin-top: 10px;
}
.modal-open {
  overflow-y: auto;
}
.btn-group-justified {
  display: table;
  width: 100%;
  table-layout: fixed;
  border-collapse: separate;
  .btn,
  .btn-group {
    float: none;
    display: table-cell;
    width: 1%;
    .btn {
      width: 100%;
    }
    .dropdown-menu {
      left: auto;
    }
  }
}
.sticked-top {
  position: sticky;
  top: 0px;
  z-index: 100;
}
.sticked-top-margin {
  @include media-breakpoint-up(lg) {
    position: sticky;
    top: 20px;
    z-index: 100;
  }
}
.scrollable-dropdownmenu {
  height: auto;
  max-height: 350px;
  overflow-x: hidden;
}
.flip-vertical-bck {
  -webkit-animation: flip-vertical-bck 1s
    cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
  animation: flip-vertical-bck 1s cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
}
.dropdown-menu{
  transform: translate3d(5px, 35px, 0px)!important;
}
.scrollable {
  height:60vh;
  overflow-y: scroll;
}
/* ----------------------------------------------
 * Generated by Animista on 2018-10-11 8:13:22
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation flip-vertical-bck
 * ----------------------------------------
 */
@-webkit-keyframes flip-vertical-bck {
  0% {
    -webkit-transform: translateZ(0) rotateY(0);
    transform: translateZ(0) rotateY(0);
  }
  100% {
    -webkit-transform: translateZ(-260px) rotateY(-180deg);
    transform: translateZ(-260px) rotateY(-180deg);
  }
}
@keyframes flip-vertical-bck {
  0% {
    -webkit-transform: translateZ(0) rotateY(0);
    transform: translateZ(0) rotateY(0);
  }
  100% {
    -webkit-transform: translateZ(-260px) rotateY(-180deg);
    transform: translateZ(-260px) rotateY(-180deg);
  }
}

.card-columns {
  @include media-breakpoint-only(sm) {
    column-count: 2;
  }
  @include media-breakpoint-only(md) {
    column-count: 2;
  }
  @include media-breakpoint-only(lg) {
    column-count: 3;
  }
  @include media-breakpoint-only(xl) {
    column-count: 4;
  }
}
.invisible {
  visibility: hidden;
}
.small {
  font-size: 1.1em;
}
.middle {
  font-size: 1.3em;
}
.big {
  font-size: 1.7em;
}
.c-transparent {
  color: #cccccc;
  background-color: transparent;
}
.c-grey {
  color: #fff;
  background-color: #cccccc;
}
.c-yellow {
  color: #fff;
  background-color: $domaine-disciplinaire-langues-color;
  //per: Langues rgb(230, 204, 0) 
}
.c-red {
  color: #fff;
  background-color: $domaine-disciplinaire-mathematiques-et-sciences-de-la-nature-color;
  //per: Mathématiques et Sciences de la nature rgb(204, 0, 1) !important
}
.c-green {
  color: #fff;
  background-color: $domaine-disciplinaire-sciences-humaines-et-sociales-color;
  //per: Sciences humaines et sociales rgb(0, 143, 20) !important;
}
.c-orange {
  color: #fff;
  background-color: $domaine-disciplinaire-arts-color;
  //per: Arts rgb(255, 89, 51) !important;
}
.c-blue {
  color: #fff;
  background-color: $domaine-disciplinaire-corps-et-mouvement-color;
  //per: Corps et mouvement rgb(0, 166, 255) !important;
}
.c-violet {
  color: #fff;
  background-color:$domaine-disciplinaire-formation-generale-color;
  //per: Formation générale rgb(76, 38, 110) !important;
}
.c-brown {
  color: #fff;
  background-color: $domaine-disciplinaire-capacites-transversales-color;
  //per: Capacités transversales #9f6d3e
}
.c-pink {
  color: #fff;
  background-color: $domaine-disciplinaire-education-numerique-color;
  //per: Éducation numérique rgb(189, 78, 161) !important;
}
.c-fushia {
  color: #fff;
  background: linear-gradient(to right,  tint-color($domaine-disciplinaire-formation-generale-color,20%) 5px, tint-color($domaine-disciplinaire-formation-generale-color,20%), $domaine-disciplinaire-formation-generale-color 5px, $domaine-disciplinaire-formation-generale-color);
  background-size: 10px 100%;
  //Folio 
}


.page-fade {
  -webkit-animation: fadein 0.8s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 0.8s; /* Firefox < 16 */
  -ms-animation: fadein 0.8s; /* Internet Explorer */
  -o-animation: fadein 0.8s; /* Opera < 12.1 */
  animation: fadein 0.8s;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.star {
  position: relative;
  display: inline-block;
  font-size: 2rem;
  color: #d3d3d3;
}
.full {
  color: red;
}

.half {
  position: absolute;
  display: inline-block;
  overflow: hidden;
  color: red;
}
.media-preview-thumbnail {
  text-align: center;
  cursor: pointer;
  img, div {
    max-height: 61px;
    width: 80px;
    object-fit: cover;
  }

}

.dot {
  vertical-align: baseline;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: inline-block;
}
#toast-container > div {
  opacity:1;
}

.activity {
  button {
    kbd {
      margin-left: .5em;
      font-size: x-small;
      vertical-align: text-bottom;
      color: inherit;
      background-color: transparent;
      border: 1px solid;
      display: none;
      @include media-breakpoint-up(lg) {
        display: inline;
      }
    }
  }
}


.roqle .ql-editor{
  padding: 0 !important;
}
.description {
  p:last-child{
    margin-bottom: 0;
  }
}

.category-tag{
  background-color: var(--bs-light);
  color: var(--bs-light-text-emphasis);
  
    
}
@include color-mode(dark) {
  .category-tag{
    background-color: var(--bs-tertiary-bg);
    color: var(--bs-dark-text-emphasis);
  }
}


.per{
  ul{
    
  }
  p{
    margin-bottom: 0;
  }
}

.per-discipline{
  border-left: 3px solid ;
  &.uc-yellow {
    border-left-color: var(--bs-domaine-disciplinaire-langues-color);
  }
  &.uc-red {
    border-left-color: var(--bs-domaine-disciplinaire-mathematiques-et-sciences-de-la-nature-color);
  }
  &.uc-green {
    border-left-color: var(--bs-domaine-disciplinaire-sciences-humaines-et-sociales-color);
  }
  &.uc-orange {
    border-left-color: var(--bs-domaine-disciplinaire-arts-color);
  }
  &.uc-blue {
    border-left-color: var(--bs-domaine-disciplinaire-corps-et-mouvement-color);
  }
  &.uc-violet {
    border-left-color: var(--bs-domaine-disciplinaire-formation-generale-color);
  }
  &.uc-brown {
    border-left-color: var(--bs-domaine-disciplinaire-capacites-transversales-color);
  }
  &.uc-pink {
    border-left-color: var(--bs-domaine-disciplinaire-education-numerique-color);
  }
}


/* quilljs width fixup*/

quill-editor{
  display: block !important;
}
/*Clipboard*/
.highlight {
  //position: relative;
  padding: 1.25rem;
  margin-bottom: 1rem;
  background-color: var(--bs-tertiary-bg);
  border-radius: var(--bs-border-radius)
}

.highlight pre {
  padding: 0;
  margin-bottom: 0;
  white-space: pre;
  background-color: transparent;
  border: 0
}

.highlight pre code {
  font-size: inherit;
  word-wrap: normal
}