@media print {

  @page {
    size: A4 landscape;
    max-height:100%;
    max-width:100%;
    margin: 1.2cm;
  }
  .container {
    max-width: none;
  }
  .toast-container, .btn, .ribbon {
    display: none;
  }
  .table-responsive{
    align-self: auto;
    overflow-x: visible;
  }
}
